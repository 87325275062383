<template>
    <v-container fluid>
        <v-card class="mx-auto lg:tw-w-1/2">
            <v-card-title>Profile</v-card-title>            
            <v-card-text>
                <div class="d-flex align-center">
                    <v-text-field
                        v-model="form.first_name"
                        :error-messages="form.errors.get('first_name')"
                        @input="form.errors.clear('first_name')"
                        label="First name"
                        autofocus
                        class="mr-4"
                    />
                    <v-text-field
                        v-model="form.last_name"
                        :error-messages="form.errors.get('last_name')"
                        @input="form.errors.clear('last_name')"
                        label="Last name"
                    />
                </div>
                <v-text-field
                    v-model="form.username"
                    :error-messages="form.errors.get('username')"
                    @input="form.errors.clear('username')"
                    label="Username"
                />
                <v-text-field
                    v-model="form.email"
                    :error-messages="form.errors.get('email')"
                    @input="form.errors.clear('email')"
                    label="Email"
                />
                <v-text-field
                    type="password"
                    v-model="form.password"
                    :error-messages="form.errors.get('password')"
                    @input="form.errors.clear('password')"
                    label="Password"
                    hint="For security purposes please enter your account password."
                    :persistent-hint="true"
                />
                <div class="d-flex align-center mt-4">
                    <v-spacer />
                    <v-btn 
                        color="primary"
                        :loading="saving"
                        @click="save"
                    >Save</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import Http from '@/utils/httpClient';
    import Form from '@/utils/form';
    import { mapGetters } from 'vuex';

    export default {
        data() {
            return {
                saving: false,
                form: new Form({
                    first_name: null,
                    last_name: null,
                    username: null,
                    email: null,
                    timezone: null,
                    password: null,
                })
            }
        },
        computed: {
            ...mapGetters(['me']),
        },
        mounted() {
            this.form.set(this.me);
        },
        methods: {
            save() {
                this.saving = true;
                Http.patch('settings/profile', this.form.payload())
                    .then(({ data }) => {
                        this.saving = false;
                        this.$store.commit('updateMe', data);
                        this.form.set({ password: null });
                        this.$root.$snackbar.open({
                            text: 'Profile settings was successfully updated.',
                            type: 'success',
                        });
                    })
                    .catch(({ response }) => {
                        this.saving = false;
                        this.form.errors.record(response.data);
                    });
            }
        }
    }    
</script>
